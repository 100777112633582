<template>
  <div class="com-page-error">
    <PostHeader
      v-show="customHeader"
      :menu-status="false"
    />

    <div class="com-page-error-main">
      <slot class="com-page-error-main">
        <div
          :class="`error-${statusComp}`"
          class="com-page-error__img"
        />
        <div class="com-page-error__text">
          {{ textComp }}
        </div>
        <div
          v-if="buttonTitleComp"
          class="com-page-error__button"
        >
          <Button
            v-if="refreshUrl"
            @click="onRefresh"
            title="刷新一下"
            type="secondary"
            width="116px"
            size="l"
          />
          <Button
            v-else
            @click="onBack"
            title="返回"
            type="secondary"
            width="116px"
            size="l"
          />
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
import PostHeader from '@/component/post-header/index.vue';
import Button from '@/ui/button';
import { popBack } from '@/jsbridge';

export default {
  name: 'PageError',
  components: {
    PostHeader,
    Button,
  },
  // 页面可以作为组件使用
  props: {
    customHeader: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '系统繁忙',
    },
    status: {
      type: String,
      validator: val => ['network', 'fail', 'empty'].indexOf(val) > -1,
      default: 'fail',
    },
    buttonTitle: {
      type: String,
      default: '刷新一下',
    },
  },
  data() {
    return {
      buttonType: '', // 'refresh'：刷新页面, 'back'：返回
      textInner: '',
      statusInner: '',
      buttonTitleInner: '',
      refreshUrl: '', // 刷新的页面
    };
  },
  computed: {
    textComp() {
      return this.textInner || this.text;
    },
    statusComp() {
      return this.statusInner || this.status;
    },
    buttonTitleComp() {
      return this.buttonTitleInner || this.buttonTitle;
    },
    isApp() {
      return this.$store.state.isApp;
    },
  },
  created() {
    const { refreshUrl, status, text } = this.$route.query;
    if (refreshUrl) this.refreshUrl = decodeURIComponent(refreshUrl);
    this.statusInner = status;
    this.textInner = text;
  },
  methods: {
    onRefresh() {
      if (this.refreshUrl) {
        window.location.replace(this.refreshUrl);
      }
    },
    onBack() {
      if (this.isApp) {
        popBack();
      } else {
        window.history.back();
      }
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
