<template>
  <button
    :class="['ui-button', `size-${size}`, `type-${type}`, { 'disabled': disabled}]"
    :style="{width: compWidth}"
    @click="onHandleClick"
  >
    <div class="ui-button-inner">
      <Icon
        v-if="icon"
        :name="icon"
        :disabled="disabled"
        class="icon"
        size="s"
      />
      <slot>
        <span>{{ title }}</span>
      </slot>
    </div>
  </button>
</template>

<script>
import Icon from '../icon/index.vue';

export default {
  components: {
    Icon,
  },
  props: {
    size: {
      type: String,
      default: 'm',
      validator(val) {
        return ['s', 'm', 'l', 'xl'].indexOf(val) > -1;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clickable: { // true:强制可点击，false:不可点击
      type: Boolean,
      default: null,
    },
    type: {
      type: String,
      default: 'default',
      validator(val) {
        return ['default', 'primary', 'secondary', 'guide', 'disabled'].indexOf(val) > -1;
      },
    },
    width: {
      type: [String, Number],
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    compWidth() {
      if (typeof this.width === 'string') {
        if (this.width === 'auto') {
          return '100%';
        }
        return this.width;
      } if (typeof this.width === 'number') {
        return `${this.width}px`;
      }
      return '';
    },
  },
  updated() {
    this.$emit('updated'); // 会多次触发，用来处理曝光的时候注意加上.once修饰符
  },
  methods: {
    onHandleClick(e) {
      if (this.clickable === true) {
        this.$emit('click', e);
      } else if (this.clickable === false) {
        return false;
      } else if (!this.disabled) {
        this.$emit('click', e);
      }
      return true;
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
