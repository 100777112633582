<template>
  <div class="com-post-header">
    <div class="com-post-header-inner">
      <slot name="left-btn">
        <div class="com-post-header-blank">
          <div
            @click="back()"
            class="icon-back"
          />
        </div>
      </slot>
      <div class="com-post-header-main">
        <slot />
      </div>
      <slot name="right-btn">
        <div
          class="com-post-header-blank"
        >
          <div
            v-show="menuStatus"
            @click="onMenuClickHandler"
            class="icon-menu"
          />
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
import { popBack } from '@/jsbridge';

export default {
  name: 'ComPostHeaader',
  props: {
    menuStatus: {
      type: Boolean,
      default: true,
    },
    autoBack: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {

    };
  },
  methods: {
    back() {
      if (this.autoBack) {
        popBack();
      } else {
        this.$emit('back');
      }
    },
    onMenuClickHandler() {
      this.$emit('menuClick');
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
